/* ==========================================================================
  main.js
========================================================================== */

'use strict';
/* global fitvids, cssVars */

/**
 * Init responsive videos
 */
fitvids();

/**
 * Init CSS vars ponyfill
 */
cssVars({});



// Get the header
var header = document.getElementById("masthead");
// Get the offset position of the navbar
var sticky = header.offsetTop;

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function stickyHeader() {

  if (window.pageYOffset > sticky) {
    header.classList.add("site-header--sticky");
  } else {
    header.classList.remove("site-header--sticky");
  }

}

// When the user scrolls the page, execute myFunction
// TODO: on page init too ?
window.onscroll = function() {
  stickyHeader();
};


/**
 * Init accordions for partenaires pages with accordion-js
 */

if ( document.querySelectorAll('.accordion-container').length ) {

  const accordions = Array.from(document.querySelectorAll('.accordion-container'));

  new Accordion(accordions, {
    'showMultiple' : true,
  });

}
